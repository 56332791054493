import { Injectable } from '@angular/core';
import {
  DocumentTableState,
  DocumentTableStore,
  DocumentTableUIIndexState,
  FetchingState,
  IndexStateEnum,
  whenTablesAreFetched,
} from './document-table-state';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import {
  selectDocumentTableIndexState,
  selectDocumentTables,
  selectDocumentTablesFetchingState,
  selectDocumentTableState,
  selectDocumentTableUIIndexState,
  selectDocumentTableUIIndexStates,
  selectDocumentTableUIIndexStatesForTables,
} from './document-table-state.selectors';
import {
  documentTableSelected,
  fetchDocumentTablesInBulk,
  refreshTables,
  reIndexDocumentTables,
  restoreDocumentTable,
} from './document-table-state.actions';
import { delay, filter, map, tap, withLatestFrom } from 'rxjs/operators';
import {
  DocumentTable,
  tableIsQueryable,
} from '../../../../nucleus/services/documentService/types';
import { arrayToMap } from '../../../../bx-common-extensions/array';
import { validTablesForDisplay } from '../../ngs/table-type-filters';
import { DocumentStatusKind } from '../../../../nucleus/v2/models/activity-events/document-activity-event.model';

@Injectable({
  providedIn: 'root',
})
export class DocumentTableStateService {
  documentTableState$: Observable<DocumentTableState>;

  constructor(private store: Store<DocumentTableStore>) {
    this.documentTableState$ = this.store.pipe(select(selectDocumentTableState));
  }

  /**
   * Get a table from the document table store, only if the table is queryable.
   *
   * @param documentID - documentID of the table
   * @param tableName - the name of the table
   * */
  getQueryableTable(documentID: string, tableName: string): Observable<DocumentTable> {
    return this.getTable(documentID, tableName).pipe(
      filter((table) => table && tableIsQueryable(table)),
    );
  }

  /**
   * Get a table from the document table store, no matter if the table is queryable.
   *
   * @param documentID - documentID of the table
   * @param tableName - the name of the table
   * */
  getTable(documentID: string, tableName: string): Observable<DocumentTable> {
    return this.documentTableState$.pipe(
      whenTablesAreFetched(documentID),
      map((state) => state.entities[documentID + tableName]),
    );
  }

  /**
   * Get a list of tables belongs to a document
   *
   * Note that if the table data isn't already populated in the store, this won't emit anything. You can ensure tables
   * are populated by calling {@link fetchTables}
   *
   * @param documentID - documentID of the table
   * */
  getTables(documentID: string): Observable<DocumentTable[]> {
    return this.store.pipe(
      select(selectDocumentTableState),
      tap((state) => {
        const maybeError = state.fetchingState[documentID]?.error;
        if (maybeError) {
          throw new Error(maybeError);
        }
      }),
      whenTablesAreFetched(documentID),
      select(selectDocumentTables(documentID)),
      map(validTablesForDisplay),
    );
  }

  /**
   * Trigger an action to populate the store with table states of all the provided document ID's
   *
   * @param documentIDs
   * */
  fetchTables(documentIDs: string[]) {
    this.store.dispatch(fetchDocumentTablesInBulk({ documentIDs }));
  }

  /**
   * Get the tables fetching state for a document. Only emit the fetching state if the document tables
   * are being fetched.
   *
   * @param documentID - documentID of the table
   * */
  getTablesFetchingState(documentID: string): Observable<FetchingState> {
    return this.store.pipe(
      select(selectDocumentTablesFetchingState(documentID)),
      filter((fetchingState) => fetchingState != null),
    );
  }

  /**
   * Get a map of tables belongs to a document.
   *
   * @param documentID - documentID of the table
   * */
  getTablesMap(documentID: string): Observable<Record<string, DocumentTable>> {
    return this.store.pipe(
      select(selectDocumentTableState),
      whenTablesAreFetched(documentID),
      select(selectDocumentTables(documentID)),
      // TODO making a map again is just inefficient
      map((tables) => arrayToMap(tables, 'name')),
    );
  }

  /**
   * Trigger a tables refresh for a given document.
   *
   * @param documentID - document to refresh.
   */
  refreshTables(documentID: string): void {
    this.store.dispatch(refreshTables({ documentID }));
  }

  /**
   * Force restore a table and return its restore state.
   *
   * @param documentID - documentID of the table
   * @param tableName - the name of the table
   * */
  forceRestoreTable(documentID: string, tableName: string): Observable<DocumentTableUIIndexState> {
    this.store.dispatch(restoreDocumentTable({ documentID, tableName }));
    return this.getUIIndexState(documentID, tableName).pipe(
      // TODO REMOVE THIS DELAY! `.dispatch` is somehow async?
      delay(0),
    );
  }

  reIndexTables(documentID: string, tableNames: string[]): Observable<DocumentTableUIIndexState[]> {
    this.store.dispatch(reIndexDocumentTables({ documentID, tableNames }));
    return this.getUIIndexStatesForTables(documentID, tableNames).pipe(
      // TODO REMOVE THIS DELAY! `.dispatch` is somehow async?
      delay(0),
    );
  }

  /**
   * Returns the table which require user to trigger manual re indexing.
   * These exclude the tables which are already being re indexed
   * @param documentID
   */
  getReIndexingRequiredTables(documentID: string): Observable<DocumentTable[]> {
    const tablesRequireDeIndexing$ = this.store.pipe(
      select(selectDocumentTableUIIndexStates(documentID)),
      map((states) =>
        states.filter((indexState) => indexState.currentIndexState === IndexStateEnum.ABSENT),
      ),
      map((states) => states.map((state) => state.tableName)),
    );
    return tablesRequireDeIndexing$.pipe(
      withLatestFrom(this.getTables(documentID)),
      map(([tableNames, allTables]) =>
        allTables.filter((table) => tableNames.includes(table.name)),
      ),
    );
  }

  /**
   * Returns the table which has no open index(tables which are not ready to be queried).
   * These include the tables with absent, error or indexing in progress.
   * @param documentID
   */
  getTablesWithNoOpenIndex(documentID: string): Observable<DocumentTable[]> {
    return this.getTables(documentID).pipe(
      map((tables) =>
        tables.filter(
          (table) =>
            table.indexState === 'absent' &&
            (table.status.kind === DocumentStatusKind.IDLE ||
              table.status.kind === DocumentStatusKind.ERROR ||
              table.status.kind === DocumentStatusKind.INDEXING),
        ),
      ),
    );
  }

  /**
   * "Select" a table. This is mostly used to trigger an effect that automatically restore the selected table in the
   * background.
   *
   * @param documentID - documentID of the table
   * @param tableName - the name of the table
   * */
  selectTable(documentID: string, tableName: string): void {
    this.store.dispatch(documentTableSelected({ documentID, tableName }));
  }

  /**
   * Get restoring state of a table. Returns RestoreState regardless of whether the table existed or not.
   *
   * @param documentID - documentID of the table
   * @param tableName - the name of the table
   * */
  getUIIndexState(documentID: string, tableName: string): Observable<DocumentTableUIIndexState> {
    return this.store.pipe(select(selectDocumentTableUIIndexState(documentID, tableName)));
  }

  getUIIndexStatesForTables(
    documentID: string,
    tableNames: string[],
  ): Observable<DocumentTableUIIndexState[]> {
    return this.store.pipe(
      select(selectDocumentTableUIIndexStatesForTables(documentID, tableNames)),
    );
  }

  /**
   * Get table index state from the document table store.
   *
   * @param documentID - documentID of the table
   * @param tableName - the name of the table
   * */
  getTableIndexState(
    documentID: string,
    tableName: string,
  ): Observable<'absent' | 'open' | 'closed' | 'archived'> {
    return this.store.pipe(select(selectDocumentTableIndexState(documentID, tableName)));
  }

  /**
   * returns all the index states of the current document
   * @param documentID
   */
  getUIIndexStatesForDocument(documentID: string): Observable<DocumentTableUIIndexState[]> {
    return this.store.pipe(select(selectDocumentTableUIIndexStates(documentID)));
  }
}
